const helpers = require("./helpers") 

var timeOutVar;
function Init () {
    if(document.getElementsByClassName('rating')[0] != undefined) {
        clearTimeout(timeOutVar);
        ready();
    } else {
        timeOutVar = setTimeout(function(){Init();}, 10);
    }
}   
Init();


function ready(){
    document.getElementsByClassName('rating')[0].onclick = function changeContent(event) {
        let point = event.target || event.srcElement;
        point = point.getAttribute("data-rating");
        alert("Selecciono " + point);
        console.log(helpers.redirectEndVignette());
    }
}
